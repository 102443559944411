import React, { useMemo, useState } from "react";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { CellProps } from "./Cell";
import { useTooltipAttributes } from "../../../../hooks";
import { colors, Csku, NoteIcon } from "@commonsku/styles";

type TColumn = typeof BASE_COLUMN_TYPES.Notes;
const NotesCell = ({
  column,
  row,
  className,
  style = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;
  const { count, href, tooltipText } = useMemo<
    ColumnDataMap<TColumn>
  >(() => {
    if (!dataTransform) {
      throw new Error("NoteCell requires dataTransform");
    }

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  const tooltipAttributes = useTooltipAttributes(
    tooltipText ?? (
      count > 0 ?
        (Number(count) === 1 ?  "1 note" : `${count} notes`) :
        "None"
    ),
    REPORT_TOOLTIP_TARGET_ID
  );

  return (
    <>
      <a
        className={className}
        style={{ cursor: "pointer", ...style }}
        href={href}
        target="_blank"
        {...tooltipAttributes}
      >
        <NoteIcon
          color={count > 0 ? colors.primary1["70"] : colors.primary1["20"]}
          altText=""
        />
      </a>
    </>
  );
};

export default NotesCell;
